/* eslint-disable */
import {
    MenuItem,
    useTheme,
    TextField,
    InputAdornment,
    Autocomplete as MuiAutocomplete,
} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import {FC, FunctionComponent, SVGProps} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {IOption} from 'modules/theme/components/fields/select/interfaces/IOption';

interface IAutocompleteSelect {
    label?: string;
    errorMsg?: string;
    options: IOption[];
    disabled?: boolean;
    ariaLabel?: string;
    selectedValue: string;
    noOptionsText?: string;
    onChange: (selectedValue: string) => void;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    placeholder?: string;
}

export const AutocompleteSelect: FC<IAutocompleteSelect> = ({
    icon,
    label,
    options,
    onChange,
    disabled,
    errorMsg,
    ariaLabel,
    selectedValue,
    placeholder,
    noOptionsText = 'Žiadne výsledky',
}) => {
    if (label && icon) {
        throw Error('You cannot use label and icon at same time. ');
    }
    const {palette, typography} = useTheme();

    const currentSelectedOption =
        options.find((option) => option.value === selectedValue) || undefined;

    const menuItem = (props, option: IOption) => (
        <MenuItem
            {...props}
            disableGutters
            key={option.value}
            value={option.value}
            disabled={option.isDisabled}
            selected={option.value === selectedValue}
            className="UCRealEstateDesignSystem-SelectMenuItem"
            sx={{
                whiteSpace: 'normal',
                fontWeight: option.isParent
                    ? typography.fontWeightBold
                    : typography.fontWeightRegular,
                color:
                    option.value === ''
                        ? palette.labelSecondary.main
                        : palette.labelPrimary.main,
                background:
                    option.isParent && palette.backgroundSecondary.main + '!important',
            }}
        >
            {option.name}
        </MenuItem>
    );

    return (
        <MuiAutocomplete
            fullWidth
            options={options}
            disabled={disabled}
            aria-label={ariaLabel}
            noOptionsText={noOptionsText}
            popupIcon={<KeyboardArrowDownIcon/>}
            value={currentSelectedOption || ''}
            getOptionLabel={(option: IOption) => option?.name || ''}
            renderOption={(props, option: IOption) => menuItem(props, option)}
            onChange={(e, option: IOption) => onChange(option?.value || '')}
            data-test-id='autocompleteSelect'
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={!!errorMsg}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: icon && (
                            <InputAdornment position="start">
                                <Svg icon component={icon} width={20}/>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};
