import {Ref, useCallback, useMemo, useRef, useState} from 'react';
import IItem from 'components/page/search/map/interfaces/IItem';
import useItems from 'modules/state/page/search/map/useItems';
import {PAGE_SIZE} from 'modules/state/page/search/map/state';
import useIsFetching from 'modules/state/page/search/map/useIsFetching';
import useFilter from 'modules/state/page/search/map/useFilter';

interface IReturn {
    items: IItem[];
    noData: boolean;
    isLoading: boolean;
    currentPage: number;
    isFetching: boolean;
    totalPageCount: number;
    listOfResultRef: Ref<HTMLDivElement>;
    handlePageChange: (page: number) => void;
}

export const useListing = (): IReturn => {
    const {items: itemsAll} = useItems();
    const { filter: { viewport } } = useFilter();
    const {isFetching} = useIsFetching();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const listOfResultRef = useRef<HTMLDivElement>(null);

    const items = useMemo(() => {
        if (!viewport) return itemsAll;

        return itemsAll.filter(item => {
            return item.location.gps.longitude >= viewport.southWest.longitude
                && item.location.gps.longitude >= viewport.northWest.longitude
                && item.location.gps.longitude <= viewport.southEast.longitude
                && item.location.gps.longitude <= viewport.northEast.longitude
                && item.location.gps.latitude <= viewport.northWest.latitude
                && item.location.gps.latitude <= viewport.northEast.latitude
                && item.location.gps.latitude >= viewport.southWest.latitude
                && item.location.gps.latitude >= viewport.southEast.latitude;
        });
    }, [itemsAll, viewport]);

    const handlePageChange = useCallback((page: number) => {
        if (currentPage === page) return;

        setCurrentPage(page);
        listOfResultRef.current?.scrollIntoView();
        window.scrollTo({top: 0});
    }, [currentPage, setCurrentPage, listOfResultRef]);


    return {
        items,
        isFetching,
        currentPage,
        listOfResultRef,
        handlePageChange,
        noData: items.length === 0 && !isFetching,
        isLoading: items.length === 0 && isFetching,
        totalPageCount: Math.ceil(items.length / PAGE_SIZE),
    };
};
