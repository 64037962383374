import {useEffect} from 'react';
import {atom} from 'recoil';
import IItem from 'components/page/search/map/interfaces/IItem';
import IFilter from 'components/page/search/map/interfaces/IFilter';
import useNotification from 'modules/notification/useNotification';
import {useDebouncedCallback} from 'use-debounce';
import useLoading from 'modules/state/app/hook/useLoading';
import useFilter from 'modules/state/page/search/map/useFilter';
import useIsFetching from 'modules/state/page/search/map/useIsFetching';
import useItems from 'modules/state/page/search/map/useItems';
import {
    DEFAULT_LATITUDE,
    DEFAULT_LONGITUDE,
    DEFAULT_ZOOM,
    IData
} from 'modules/state/page/search/map/useMap';
import {devProjectsSearchMapApi} from 'modules/api/client';
import useSelectedItemId from 'modules/state/page/search/map/useSelectedItemId';

export const PAGE_SIZE = 20;

export const filterState = atom<IFilter>({
    key: 'page-search-map-index-filter',
    default: {}
});

export const isFetchingState = atom<boolean>({
    key: 'page-search-map-index-isFetching',
    default: true
});

export const itemsState = atom<IItem[]>({
    key: 'page-search-map-index-items',
    default: []
});

export const hoveredItemIdState = atom<string>({
    key: 'page-search-map-index-hoveredItemId',
    default: null
});

export const selectedItemIdState = atom<string>({
    key: 'page-search-map-index-selectedItemId',
    default: null
});

export const mapDataState = atom<IData>({
    key: 'page-search-map-index-map-data',
    default: {
        zoom: DEFAULT_ZOOM,
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE
    }
});

export const mapShouldResetState = atom<boolean>({
    key: 'page-search-map-index-map-should-reset',
    default: false
});

export const useState = (): void => {
    const { start: startLoading, stop: stopLoading } = useLoading();
    const { error: showErrorNotification } = useNotification();

    const { filter } = useFilter();
    const { setIsFetching } = useIsFetching();
    const { setItems } = useItems();
    const { selectedItemId, setSelectedItemId } = useSelectedItemId();

    const fetchItems = async () => {
        startLoading();
        setIsFetching(true);

        try {
            const apiFilter = {...filter};
            delete apiFilter.locationId;
            delete apiFilter.viewport;
            const offers = (await devProjectsSearchMapApi.getProjects(apiFilter))?.data || [];
            setItems(offers);
            !offers.some( offer => offer.id === selectedItemId) && setSelectedItemId('');
        } catch (error) {
            setItems([]);
            showErrorNotification('Nepodarilo sa načítať inzeráty');
        } finally {
            setIsFetching(false);
            stopLoading();
        }
    };

    const fetchItemsDebounced = useDebouncedCallback(fetchItems, 1000);

    const filterForWatch = {...filter};
    delete filterForWatch.viewport;
    const filterForWatchSerialized = JSON.stringify(filterForWatch);

    useEffect(() => {
        fetchItemsDebounced();
    }, [filterForWatchSerialized, fetchItemsDebounced]);
};
