import {FC} from 'react';
import {Badge as MuiBadge, Box, Theme} from '@mui/material';

interface IBadge {
    showBadge: boolean;
    children: JSX.Element
}

export const Badge: FC<IBadge> = ({children, showBadge}) => {
    const circle = <Box
        width={6}
        height={6}
        borderRadius={3}
        bgcolor={(theme: Theme) => theme.palette.primary.main}
        boxShadow={(theme: Theme) => '0 0 0 1px ' + theme.palette.backgroundPrimary.main}
    />;

    return <MuiBadge
        overlap="circular"
        badgeContent={showBadge && circle}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
    >
        {children}
    </MuiBadge>;
};
