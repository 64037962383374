import { useRecoilState } from 'recoil';
import { itemsState } from 'modules/state/page/search/map/state';
import IItem from 'components/page/search/map/interfaces/IItem';

interface IReturn {
    items: IItem[];
    setItems: (items: IItem[]) => void;
}

const useItems = (): IReturn => {
    const [ items, setItems ] = useRecoilState(itemsState);

    return {
        items,
        setItems
    };
};

export default useItems;
