import {FC, useState} from 'react';
import {Container, Theme} from '@mui/material';
import useFilter from 'modules/state/page/search/map/useFilter';
import {Menu} from 'components/page/search/map/components/filter/mobile/menu/Menu';
import useSelectedItemId from 'modules/state/page/search/map/useSelectedItemId';
import {Header} from 'components/page/search/map/components/filter/mobile/header/Header';

interface IFilter {
    locationTitle: string;
}

export const Filter: FC<IFilter> = ({locationTitle}) => {
    const {isFilterEmpty} = useFilter();
    const {setSelectedItemId} = useSelectedItemId();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const toggleMenu = () => {
        setSelectedItemId('');
        setIsMenuOpen(currentState => !currentState);
    };

    return <>
        <Container>
            <Header
                onClick={toggleMenu}
                showResetBtn={!isFilterEmpty}
                showBadge={!isFilterEmpty}
                locationTitle={locationTitle}
            />
        </Container>

        {isMenuOpen &&
            <Container sx={(theme: Theme) => ({
                top: 64,
                width: '100%',
                paddingTop: 2,
                height: '100%',
                position: 'fixed',
                overflowY: 'scroll',
                zIndex: theme.zIndex.modal,
                backgroundColor: theme.palette.backgroundPrimary.main,
            })}>
                <Menu
                    onClose={toggleMenu}
                    onSubmit={toggleMenu}
                    isResetBtnDisabled={isFilterEmpty}
                />
            </Container>
        }
    </>;
};
