import { useRecoilState } from 'recoil';
import { isFetchingState } from 'modules/state/page/search/map/state';

interface IReturn {
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}

const useIsFetching = (): IReturn => {
    const [ isFetching, setIsFetching ] = useRecoilState(isFetchingState);

    return {
        isFetching,
        setIsFetching
    };
};

export default useIsFetching;
