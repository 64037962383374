import {FC} from 'react';
import {Container, Grid, Theme} from '@mui/material';
import filterStateValues from 'components/page/search/map/helpers/filter/filterStateValues';
import filterCategoryValues from 'components/page/search/map/helpers/filter/filterCategoryValues';
import {ResetButton} from 'components/page/search/map/components/filter/mobile/common/ResetButton';
import {ALL_PROJECTS_FIELD_NAME} from 'components/page/search/map/constantns/allProjectsFiledName';
import {Headline} from 'components/page/search/map/components/filter/mobile/menu/headline/Headline';

import {Select} from 'components/page/search/map/components/filter/common/fields/Select';
import {Checkbox} from 'components/page/search/map/components/filter/common/fields/Checkbox';
import {TextInput} from 'components/page/search/map/components/filter/common/fields/TextInput';
import {Autocomplete} from 'components/page/search/map/components/filter/common/fields/Autocomplete';
import {Button} from 'modules/theme/components/button/Button';
import {locationSeoMethods} from 'components/page/search/map/helpers/locationSeoMethods';

interface IMenu {
    onClose: () => void;
    onSubmit: () => void;
    isResetBtnDisabled: boolean;
}

export const Menu: FC<IMenu> = ({onClose, onSubmit, isResetBtnDisabled}) => {
    const {getLocationOptions} = locationSeoMethods();

    return <Grid container height="100%" alignContent="baseline">

        <Grid item xs={12} container spacing={2} alignItems="center" paddingBottom={20}>

            <Grid item xs={12}>
                <Headline onCloseIconClick={onClose}/>
            </Grid>

            <Grid item xs={12} display="flex" >
                <TextInput fieldName="title" label="Napíšte čo hľadáte"/>
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    fieldName="location_id"
                    label="Zadajte okres alebo kraj"
                    options={getLocationOptions}
                />
            </Grid>

            <Grid item xs={12}>
                <Select fieldName="category" filterValues={filterCategoryValues}/>
            </Grid>

            <Grid item xs={12}>
                <Select fieldName="real_estate_state" filterValues={filterStateValues}/>
            </Grid>

            <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                    <TextInput fieldName="price_from" type="number" label="Cena od"/>
                </Grid>

                <Grid item xs={6}>
                    <TextInput fieldName="price_to" type="number" label="Cena do"/>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Checkbox fieldName={ALL_PROJECTS_FIELD_NAME} label="Len exkluzívne projekty"/>
            </Grid>
        </Grid>


        <Container sx={(theme: Theme) => ({
            left: 0,
            bottom: 0,
            zIndex: 3,
            position: 'fixed',
            backgroundColor: theme.palette.backgroundPrimary.main,
            boxShadow: '0px -4px 4px ' + theme.palette.labelQuarternary.main,
        })}>
            <Grid
                item
                py={2}
                xs={12}
                container
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={6}>
                    <ResetButton isDisabled={isResetBtnDisabled}/>
                </Grid>

                <Grid item xs={6}>
                    <Button fullWidth text="Zobraziť výsledky" onClick={onSubmit}/>
                </Grid>
            </Grid>

        </Container>
    </Grid>;
};
