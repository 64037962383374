import React, {FC} from 'react';
import {Grid} from '@mui/material';
import {
    ItemSkeleton
} from 'components/page/search/map/components/searchResult/listing/advertisemensList/loading/ItemSkeleton';

export const LoadingContent: FC = () => {
    return <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
            <ItemSkeleton/>
        </Grid>
        <Grid item xs={6}>
            <ItemSkeleton/>
        </Grid>
        <Grid item xs={6}>
            <ItemSkeleton/>
        </Grid>
        <Grid item xs={6}>
            <ItemSkeleton/>
        </Grid>
        <Grid item xs={6}>
            <ItemSkeleton/>
        </Grid>
    </Grid>;
};

