import {FC, InputHTMLAttributes} from 'react';
import { Box } from '@mui/material';
import {Input} from 'modules/theme/components/fields/input/Input';
import Field from '@uc-platform/advertisement-search-form/dist/es5/Components/Fields/Types/Field';
import useFieldContext from '@uc-platform/advertisement-search-form/dist/es5/Components/Hooks/useFieldContext';

interface IProps {
    label: string;
    fieldName: string;
    type?: InputHTMLAttributes<string>['type'];
    testId?: string;
}

export const TextInput: FC<IProps> = ({ fieldName, label, type, testId }) => {
    const {value, setValue} = useFieldContext(fieldName, Field.OPERATION_EQUAL);

    return (
        <Box component="span" data-test-id={testId} sx={{ width: 1 }}>
            <Input
                type={type}
                label={label}
                value={value || ''}
                onChange={value => setValue(value)}
            />
        </Box>
    );
};
