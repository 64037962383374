import { useRecoilState } from 'recoil';
import { selectedItemIdState } from 'modules/state/page/search/map/state';

interface IReturn {
    selectedItemId: string;
    setSelectedItemId: (selectedItemId: string) => void;
}

const useSelectedItemId = (): IReturn => {
    const [ selectedItemId, setSelectedItemId ] = useRecoilState(selectedItemIdState);

    return {
        selectedItemId,
        setSelectedItemId
    };
};

export default useSelectedItemId;
