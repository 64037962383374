import { FC } from 'react';
import { Box } from '@mui/material';
import {IOption} from 'modules/theme/components/fields/select/interfaces/IOption';
import {Select as DesignSelect} from 'modules/theme/components/fields/select/Select';
import { filterSelectValues } from 'modules/advertisement/searchForm/field/filterSelectValues';
import Field from '@uc-platform/advertisement-search-form/dist/es5/Components/Fields/Types/Field';
import useFieldContext from '@uc-platform/advertisement-search-form/dist/es5/Components/Hooks/useFieldContext';
import {IFormValue} from '@uc-platform/advertisement-search-form/dist/es5/Components/SearchForm/SearchFormInterfaces';

interface IProps {
    fieldName: string;
    filterValues?: (values: IFormValue[]) => IFormValue[];
    testId?: string;
}

export const Select: FC<IProps> = ({ fieldName, filterValues, testId }) => {
    const {value, setValue, parameterData} = useFieldContext(fieldName, Field.OPERATION_EQUAL);

    const options: IOption[] = (filterValues || filterSelectValues)(Object.values(parameterData.values)).map(value => ({
        value: value.uniqueKey,
        name: value.name
    }));

    return (
        <Box component="span" data-test-id={testId}>
            <DesignSelect
                options={options}
                placeholder={'vyberte...'}
                label={parameterData.name}
                selectedValue={value || ''}
                onChange={value => setValue(value)}
            />
        </Box>
    );
};
