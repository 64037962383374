import { useRecoilState } from 'recoil';
import IFilter from 'components/page/search/map/interfaces/IFilter';
import { filterState } from 'modules/state/page/search/map/state';

interface IReturn {
    filter: IFilter;
    setFilter: (filter: IFilter) => void;
    isFilterEmpty: boolean;
}

const useFilter = (): IReturn => {
    const [ filter, setFilter ] = useRecoilState(filterState);
    const isFilterEmpty = (
        filter.locationId
        || filter.fulltext
        || filter.transaction
        || filter.category 
        || filter.state
        || filter?.price?.from
        || filter?.price?.to
    ) === undefined && filter.allProjects === true;

    return {
        filter,
        setFilter,
        isFilterEmpty,
    };
};

export default useFilter;
