import { useRecoilState } from 'recoil';
import { hoveredItemIdState } from 'modules/state/page/search/map/state';

interface IReturn {
    hoveredItemId: string;
    setHoveredItemId: (hoveredItemId: string) => void;
}

const useHoveredItemId = (): IReturn => {
    const [ hoveredItemId, setHoveredItemId ] = useRecoilState(hoveredItemIdState);

    return {
        hoveredItemId,
        setHoveredItemId
    };
};

export default useHoveredItemId;
