import React, { FC } from 'react';
import Image from 'components/image/Image';
import { Grid, useTheme } from '@mui/material';
import { ITextLabel } from 'components/page/search/map/interfaces/ITextLabel';
import BadgeDevProject from 'components/page/search/map/svg/badgeDevProject.svg?url';
import { TextLabel } from 'components/page/search/map/components/searchResult/advertisement/labels/partials/TextLabel';
import { BadgeLabel } from 'components/page/search/map/components/searchResult/advertisement/labels/partials/BadgeLabel';
import { NumberOfPhotos } from 'components/page/search/map/components/searchResult/advertisement/labels/partials/NumberOfPhotos';

export interface ILabel {
    isTop: boolean;
    isPremium: boolean;
    numberOfPhotos: number;
}

export const Labels: FC<ILabel> = ({ isTop, isPremium, numberOfPhotos }) => {
    const { palette } = useTheme();
    let advertisementPackage: ITextLabel;

    const showTextLabel = isTop || isPremium;
    const showNumberOfPhotosLabel = numberOfPhotos > 0;

    const packages: Record<string, ITextLabel> = {
        top: { text: 'TOP', bgcolor: palette.primary.main },
        premium: { text: 'PREMIUM', bgcolor: palette.tintPurple.main },
    };

    if (isTop) advertisementPackage = packages['top'];
    if (isPremium) advertisementPackage = packages['premium'];

    return (
        <Grid container p={1} spacing={1}>
            <Grid item>
                <BadgeLabel>
                    <Image
                        alt={''}
                        width={72}
                        height={72}
                        src={BadgeDevProject}
                    />
                </BadgeLabel>
            </Grid>

            {showTextLabel && (
                <Grid item>
                    <TextLabel
                        text={advertisementPackage.text}
                        bgcolor={advertisementPackage.bgcolor}
                    />
                </Grid>
            )}

            {showNumberOfPhotosLabel && (
                <Grid item>
                    <NumberOfPhotos numberOfPhotos={numberOfPhotos} />
                </Grid>
            )}
        </Grid>
    );
};
