import {FC} from 'react';
import {Box, useTheme} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import CameraIcon from 'modules/theme/components/icons/basic/Camera.svg';
import { Svg } from 'modules/theme/components/svg/Svg';


interface IBadgeLabel {
    numberOfPhotos: number;
}

export const NumberOfPhotos: FC<IBadgeLabel> = ({numberOfPhotos}) => {
    const {palette} = useTheme();

    return <Box
        py={.3}
        px={.75}
        borderRadius={1}
        sx={{opacity: .9}}
        bgcolor={palette.backgroundSecondaryInverse.main}
        boxShadow={'0px 0px 50px 2px ' + palette.labelQuarternary.main}
    >
        <Text variant="label2" bold color={palette.labelPrimaryInverse}>
            <Box display="flex" gap={.5}>
                <Svg icon component={CameraIcon} width={16}/>
                {numberOfPhotos}
            </Box>
        </Text>
    </Box>;
};
