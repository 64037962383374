import React, {FC} from 'react';
import {Filter as MobileFilter} from 'components/page/search/map/components/filter/mobile/Filter';
import {Filter as DesktopFilter} from 'components/page/search/map/components/filter/desktop/Filter';
import {FormWrapper} from 'components/page/search/map/components/filter/formWrapper/FormWrapper';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IFilterProps {
    defaultLocationId: string;
    locationTitle?: string;
}

export const Filter: FC<IFilterProps> = ({defaultLocationId, locationTitle}) => {
    return <FormWrapper defaultLocationId={defaultLocationId}>
        <>
            <Mobile>
                <MobileFilter locationTitle={locationTitle} />
            </Mobile>
            <Desktop>
                <DesktopFilter />
            </Desktop>
        </>
    </FormWrapper>;
};
