import {
    SearchFormContext
} from '@uc-platform/advertisement-search-form/dist/es5/Components/SearchForm/SearchFormContext';

import {FC, useContext} from 'react';
import {Button, Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import useMap from 'modules/state/page/search/map/useMap';


interface IResetButtonProps {
    isDisabled: boolean;
}

export const ResetButton: FC<IResetButtonProps> = ({isDisabled}) => {
    const searchFormContext = useContext(SearchFormContext);
    const {setShouldReset: setShouldResetMap} = useMap();

    const handleReset = () => {
        searchFormContext?.contextActions.resetForm();
        setShouldResetMap(true);
    };

    return <Button variant="text" onClick={handleReset} disabled={isDisabled} size="small" sx={{padding: 0.5}}>
        <Text
            variant="body2"
            bold={!isDisabled}
            color={(palette: Palette) => isDisabled ? palette.labelTertiary : palette.primary}
        >
            Resetovať filter
        </Text>
    </Button>;
};

