import {FC} from 'react';
import {Grid} from '@mui/material';
import {ResetButton} from 'components/page/search/map/components/filter/mobile/common/ResetButton';
import {Headline} from 'components/page/search/map/components/filter/mobile/header/headline/Headline';
import {MenuButton} from 'components/page/search/map/components/filter/mobile/header/menuButton/MenuButton';

interface IHeader {
    showBadge: boolean;
    onClick: () => void;
    showResetBtn: boolean;
    locationTitle: string
}

export const Header: FC<IHeader> = ({showBadge, onClick, showResetBtn, locationTitle}) => {
    return <Grid container alignItems="center" justifyContent="space-between" py={1.5}>

        <Grid item xs="auto">
            <Headline locationTitle={locationTitle}/>
        </Grid>

        <Grid item xs="auto">
            <MenuButton showBadge={showBadge} onClick={onClick}/>
        </Grid>

        {showResetBtn &&
            <Grid item xs={12} textAlign="right">
                <ResetButton isDisabled={false}/>
            </Grid>
        }
    </Grid>;
};
