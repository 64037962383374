interface IgetRegionMeta {
    locationTitle: string;
    locationRegionMainCity: string;
    locationTitleDeclension: string;
}

export const getRegionMeta = (selectedLocationSef: string): IgetRegionMeta => {
    let locationTitle = '';
    let locationRegionMainCity = '';
    let locationTitleDeclension = '';

    switch (selectedLocationSef) {
    case 'presovsky-kraj': {
        locationRegionMainCity = 'Prešov';
        locationTitle = 'Prešovský kraj';
        locationTitleDeclension = 'Prešovskom kraji';
        break;
    }
    case 'kosicky-kraj': {
        locationRegionMainCity = 'Košice';
        locationTitle = 'Košický kraj';
        locationTitleDeclension = 'Košickom kraji';
        break;
    }
    case 'banskobystricky-kraj': {
        locationRegionMainCity = 'Banská Bystrica';
        locationTitle = 'Banskobystrický kraj';
        locationTitleDeclension = 'Banskobystrickom kraji';
        break;
    }
    case 'trenciansky-kraj': {
        locationRegionMainCity = 'Trenčín';
        locationTitle = 'Trenčiansky kraj';
        locationTitleDeclension = 'Trenčianskom kraji';
        break;
    }
    case 'bratislavsky-kraj': {
        locationRegionMainCity = 'Bratislava';
        locationTitle = 'Bratislavský kraj';
        locationTitleDeclension = 'Bratislavskom kraji';
        break;
    }
    case 'nitriansky-kraj': {
        locationRegionMainCity = 'Nitra';
        locationTitle = 'Nitriansky kraj';
        locationTitleDeclension = 'Nitrianskom kraji';
        break;
    }
    case 'trnavsky-kraj': {
        locationRegionMainCity = 'Trnava';
        locationTitle = 'Trnavský kraj';
        locationTitleDeclension = 'Trnavskom kraji';
        break;
    }
    case 'zilinsky-kraj': {
        locationRegionMainCity = 'Žilina';
        locationTitle = 'Žilinský kraj';
        locationTitleDeclension = 'Trnavskom kraji';
        break;
    }
    }

    return {
        locationTitle,
        locationRegionMainCity,
        locationTitleDeclension,
    };
};