import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface IHeadline {
    locationTitle: string
}

export const Headline: FC<IHeadline> = ({locationTitle}) => {
    return <Text variant="h3" component="h1" semibold>
        <>Novostavby{locationTitle && <> {locationTitle}</>}</>
    </Text>;
};
