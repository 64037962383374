import { useRecoilState } from 'recoil';
import { mapDataState, mapShouldResetState } from 'modules/state/page/search/map/state';
import {Theme, useMediaQuery} from '@mui/material';
export interface IData{
    zoom: number;
    latitude: number
    longitude: number;
}

export const DEFAULT_ZOOM = 10;
export const DEFAULT_LATITUDE = 48.35539427867962;
export const DEFAULT_LONGITUDE = 17.60073635183159;
export const DEFAULT_LATITUDE_MOBILE = 48.3396197221074;
export const DEFAULT_LONGITUDE_MOBILE = 17.262891114223752;

interface IReturn {
    data: IData;
    setData: (data: IData) => void;
    shouldReset: boolean;
    setShouldReset: (shouldReset: boolean) => void;
    defaultData: IData;
    isDefaultData: boolean;
    defaultDataMobile: IData;
    isMobile: boolean;
}

const defaultData: IData = {zoom: DEFAULT_ZOOM, latitude: DEFAULT_LATITUDE, longitude: DEFAULT_LONGITUDE};
const defaultDataMobile: IData = {zoom: DEFAULT_ZOOM, latitude: DEFAULT_LATITUDE_MOBILE, longitude: DEFAULT_LONGITUDE_MOBILE};

const useMap = (): IReturn => {
    const [ data, setData ] = useRecoilState(mapDataState);
    const [ shouldReset, setShouldReset ] = useRecoilState(mapShouldResetState);

    const isDefaultData = (data.zoom === defaultData.zoom &&
        data.longitude.toFixed(1) === defaultData.longitude.toFixed(1) &&
        data.latitude.toFixed(1) === defaultData.latitude.toFixed(1))
        || (data.zoom === defaultDataMobile.zoom &&
            data.longitude.toFixed(1) === defaultDataMobile.longitude.toFixed(1) &&
            data.latitude.toFixed(1) === defaultDataMobile.latitude.toFixed(1));

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return {
        data,
        setData,
        shouldReset,
        setShouldReset,
        defaultData,
        isDefaultData,
        defaultDataMobile,
        isMobile
    };
};

export default useMap;
