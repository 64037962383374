import React, {FC} from 'react';
import {EUnitPriceId} from 'components/page/search/map/enum/EUnitPriceId';
import {EPriceOptions} from 'components/page/search/map/enum/EPriceOptions';
import {TextPrice} from 'components/page/search/map/components/searchResult/advertisement/priceInfo/partials/TextPrice';
import {
    priceOptionIdToPriceOptionNameMapper
} from 'components/page/search/map/mappers/priceOptionIdToPriceOptionNameMapper';
import {
    NumericalPrice
} from 'components/page/search/map/components/searchResult/advertisement/priceInfo/partials/NumericalPrice';
import {Box} from '@mui/material';

interface IPriceInfo {
    priceFrom: number;
    unitFrom?: number;
    isPremium: boolean;
    isMapItem: boolean;
    unitUnitId?: EUnitPriceId;
    priceOption: EPriceOptions;
    priceUnitId?: EUnitPriceId;
}

export const PriceInfo: FC<IPriceInfo> = ({
    unitFrom,
    isPremium,
    priceFrom,
    isMapItem,
    unitUnitId,
    priceOption,
    priceUnitId,
}) => {
    const isNumericalPrice = priceOption === EPriceOptions.PRICE;
    const isPriceHidden = priceOption === EPriceOptions.PRICE_HIDDEN;


    return <Box
        display="flex"
        flexDirection="column"
        alignItems={{sm: isMapItem ? 'start' : isPremium ? 'end' : 'start'}}
    >
        {isPriceHidden && <></>}

        {isNumericalPrice &&
            <NumericalPrice
                priceFrom={priceFrom}
                unitFrom={unitFrom}
                unitUnitId={unitUnitId}
                priceUnitId={priceUnitId}
            />
        }

        <TextPrice text={priceOptionIdToPriceOptionNameMapper[priceOption]}/>
    </Box>;

};
